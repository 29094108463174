@import '../../../../scss/theme-bootstrap';

body.active-panel {
  @media #{$small-down} {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.slide-content-panel {
  // Article editorial
  .basic-textarea-v1 {
    padding: 0 8%;
    font-size: 16px;
    p {
      line-height: 1.5;
      margin-bottom: 20px;
    }
    ul {
      margin: 20px auto 40px;
    }
    li {
      list-style: disc;
      margin-bottom: 15px;
    }
    strong {
      font-family: $base-black-font-family;
      font-weight: bold;
    }
  }
  &__reveal {
    display: none;
    &-backdrop {
      transition: opacity 0.3s;
      position: fixed;
      z-index: 10000;
      top: 0;
      #{$ldirection}: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      background: $cr19-bg-dark-transparent;
    }
    &-content {
      background: $color-white;
      position: fixed;
      z-index: 10001;
      transition: transform 0.5s, opacity 0.3s;
      transform: translateX(100%);
      opacity: 0;
      width: 100%;
      height: 100%;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      top: 0;
      #{$rdirection}: 0;
      @media #{$cr19-large-up} {
        width: 78%;
        max-width: $large-med;
      }
    }
    &-close {
      display: block;
      width: 22px;
      height: 22px;
      border: none;
      -webkit-appearance: none;
      background: transparent;
      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  article header {
    display: flex;
    background: $color-white;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 58px;
    position: sticky;
    top: 0;
    z-index: 99;
    @media #{$cr19-medium-up} {
      padding: 0 42px;
      height: 75px;
      position: relative;
    }
  }
  .eh-content-formatter-v1 h3 {
    border-bottom-width: 3px;
  }
  .eh-carousel-v1.crash-carousel h3 {
    margin: 40px auto;
  }
  .eh-carousel-v1.crash-carousel {
    margin: 0;
  }
  .eh_hero_article_images_v2 {
    padding: 0 0 20px 0;
  }
  .flex-viewport .slides img {
    margin: auto;
    width: auto;
  }
  .btn-quickbuy {
    display: none;
  }
}

body.active-content-panel {
  overflow: hidden;
  .slide-content-panel {
    &__reveal {
      display: block;
    }
  }
}

body.active-content-panel-animation {
  .slide-content-panel {
    &__reveal-backdrop {
      opacity: 1;
    }
    &__reveal-content {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

#cboxOverlay {
  background: $color-white;
  @if $cr19 == true {
    background: $cr19-bg-dark-transparent;
  }
}

.once_hub_pop_up {
  #cboxTopLeft,
  #cboxTopCenter,
  #cboxTopRight,
  #cboxMiddleRight,
  #cboxBottomLeft,
  #cboxBottomCenter,
  #cboxBottomRight,
  #cboxMiddleLeft {
    display: none;
  }
  #colorbox {
    transform: unset;
    margin-#{$ldirection}: 0;
    background: $color-white;
    @media #{$cr19-medium-up} {
      transform: translate(-50%, 0);
    }
    #cboxWrapper {
      padding: 0;
      #cboxContent {
        #cboxClose {
          #{$rdirection}: 10px;
          top: 50px;
          background: url('../../../../../cl_base/img/icons/src/x-16.svg') no-repeat;
          width: 17.4px;
          height: 17.4px;
        }
      }
    }
  }
}
  